import { Injectable, computed, inject, signal } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { ProductQuickSearchListItem } from '@idealsupply/ngclient-webservice-inventory';
import { SearchService, SearchServiceState } from 'app-shell-ui';
import { BehaviorSubject, catchError, debounceTime, distinctUntilChanged, firstValueFrom, map, of, share, switchMap, tap } from 'rxjs';

import { ProductQuickSearchService as QuickSearchService } from 'product-quick-search-data';

@Injectable()
export class ProductQuickSearchService implements SearchService<ProductQuickSearchListItem[]> {
  private readonly _shoppingService = inject(QuickSearchService);
  private readonly _state = signal(SearchServiceState.Idle);
  private readonly _error = signal<any>(undefined);
  private readonly _searchQuery$$ = new BehaviorSubject<string>('');

  private readonly _searchResults$ = this._searchQuery$$
    .pipe(
      tap(() => {
        if (this._state() === SearchServiceState.Idle) {
          this._state.set(SearchServiceState.Typing);
        }
      }),
      map((value) => value.trim()),
      distinctUntilChanged(),
      debounceTime(300),
      tap(() => {
        this._state.set(SearchServiceState.Loading);
      }),
      switchMap((value) => (!!value ? this._shoppingService.quickSearch(value) : of<ProductQuickSearchListItem[]>([]))),
      tap((value: ProductQuickSearchListItem[]) => {
        this._state.set(value?.length ? SearchServiceState.Success : SearchServiceState.Idle);
        this._error.set(undefined);
      }),
      catchError((e) => {
        this._error.set(e);
        this._state.set(SearchServiceState.Error);
        return of<ProductQuickSearchListItem[]>([]);
      }),
    )
    .pipe(share(), takeUntilDestroyed());

  public readonly searchState = this._state.asReadonly();
  public readonly searchError = this._error.asReadonly();
  public readonly searchQuery = toSignal(this._searchQuery$$, { initialValue: '' });
  public readonly searchResults = toSignal(this._searchResults$, { initialValue: [] });
  public readonly searchResultCount = computed(() => this.searchResults().length);

  public async search(value: string) {
    this._searchQuery$$.next(value);
    return firstValueFrom(this._searchResults$);
  }
}
