import { Provider } from '@angular/core';
import { SearchService } from 'app-shell-ui';
import { provideFeatureImportComponents } from 'common-util';
import { ProductQuickSearchService } from './product-quick-search.service';
import { ProductQuickSearchResultsProviderComponent } from './provided-components/product-quick-search-results-provider/product-quick-search-results-provider.component';

export function provideProductQuickSearchFeature(): Provider {
  return [
    provideFeatureImportComponents(ProductQuickSearchResultsProviderComponent),
    {
      provide: SearchService,
      useClass: ProductQuickSearchService,
    },
  ];
}
