import { JsonPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { ProductQuickSearchListItem, StockLocationType } from '@idealsupply/ngclient-webservice-inventory';
import { NgOptimizedImage } from '@angular/common';

@Component({
  selector: 'ideal-product-quick-search-item',
  standalone: true,
  imports: [JsonPipe, RouterModule, MatButtonModule, NgOptimizedImage],
  templateUrl: './product-quick-search-item.component.html',
  styleUrl: './product-quick-search-item.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductQuickSearchItemComponent {
  protected readonly StockLocationType = StockLocationType;
  public readonly product = input.required<ProductQuickSearchListItem>();
  public readonly searchTerm = input.required<string>();

  onImageLoaded(e: Event) {
    //console.log('onImageLoaded', e);
  }

  onImageError(e: Event) {
    //console.log('onImageError', e);
  }

  public makeMatchedString(item: ProductQuickSearchListItem): string {
    const searchFor = this.searchTerm().trim();
    const searchForL = searchFor.toLowerCase();
    const search = item.matchedKeyword || item.partNumber;
    const searchL = search.toLowerCase();
    const idx = searchL.indexOf(searchForL);
    const one = search.substr(0, idx);
    const two = search.substr(idx, searchFor.length);
    const three = search.substr(idx + searchFor.length);
    return `${one}<span class="search-match">${two}</span>${three}`;
  }
}
