import { Injectable, inject } from '@angular/core';
import { InventoryApi, ProductQuickSearchListItem } from '@idealsupply/ngclient-webservice-inventory';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProductQuickSearchService {
  private readonly _inventoryApi = inject(InventoryApi);

  public quickSearch(value: string): Observable<ProductQuickSearchListItem[]> {
    return this._inventoryApi.quickSearchProducts(value);
  }
}
