import { JsonPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { MatListItem, MatNavList } from '@angular/material/list';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { ProductQuickSearchListItem } from '@idealsupply/ngclient-webservice-inventory';
import { ProductQuickSearchItemComponent } from '../product-quick-search-item';

@Component({
  selector: 'ideal-product-quick-search-results',
  standalone: true,
  imports: [MatNavList, MatListItem, RouterLink, RouterLinkActive, ProductQuickSearchItemComponent, JsonPipe],
  templateUrl: './product-quick-search-results.component.html',
  styleUrl: './product-quick-search-results.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductQuickSearchResultsComponent {
  public readonly searchResults = input.required<ProductQuickSearchListItem[]>();
  public readonly searchTerm = input.required<string>();
}
