<div class="product">
  <div class="image-container">
    <div class="image-border">
      @if (product().imageUrl?.trim()) {
        <img [ngSrc]="product().imageUrl! + ''" fill (load)="onImageLoaded($event)" (error)="onImageError($event)" />
      } @else {
        <img class="placeholder" src="assets/logos/link.svg" />
      }
    </div>
  </div>
  <div class="info-container">
    <div>
      <label>
        <span [innerHtml]="makeMatchedString(product()) + ' | '"></span>
        <ng-container>
          {{ product().partNumber }}
        </ng-container>
      </label>

      @switch (product().stockLocation) {
        @case (StockLocationType.InStock) {
          <label class="stock-location in-stock">In Warehouse</label>
        }
        @case (StockLocationType.InWarehouse) {
          <label class="stock-location in-warehouse">In Stock</label>
        }
        @case (StockLocationType.OnBackOrder) {
          <label class="stock-location on-back-order">On Back Order</label>
        }
      }
    </div>
    <div>
      <label>{{ product().description }}</label>
    </div>
  </div>
</div>
