import { isPlatformBrowser } from '@angular/common';
import { APP_INITIALIZER, PLATFORM_ID, Provider } from '@angular/core';
import { MatomoAnalyticsService, WithMatomoConfig } from 'analytics-data';
import { APP_CONFIG, AppConfig } from 'config-data';
import { MATOMO_CONFIGURATION, MatomoConfiguration, MatomoInjector, MatomoModule, MatomoRouteTracker, MatomoTracker } from 'ngx-matomo';

export function provideAnalyticsFeature(): Provider {
  return [provideMatomoAnalyticsFeature()];
}

export function provideMatomoAnalyticsFeature(): Provider {
  return typeof window === 'undefined'
    ? []
    : [
        MatomoModule,
        {
          provide: MATOMO_CONFIGURATION,
          deps: [APP_CONFIG, PLATFORM_ID],
          useFactory: (appConfig: AppConfig & WithMatomoConfig, platformId: any) =>
            isPlatformBrowser(platformId) ? (appConfig.matomo ?? null) : null,
        },
        MatomoTracker,
        MatomoRouteTracker,
        MatomoInjector,
        {
          provide: APP_INITIALIZER,
          deps: [MatomoInjector, MATOMO_CONFIGURATION],
          useFactory: (injector: MatomoInjector, config: MatomoConfiguration | null) => () => {
            if (config) {
              injector.init();
            }
          },
          multi: true,
        },
        {
          provide: APP_INITIALIZER,
          deps: [MatomoAnalyticsService],
          useFactory: (matomo: MatomoAnalyticsService) => () => {},
          multi: true,
        },
      ];
}
