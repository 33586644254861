import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { ProductQuickSearchListItem } from '@idealsupply/ngclient-webservice-inventory';
import { RenderInAppQuickSearchResultsDirective, SearchService } from 'app-shell-ui';
import { ProductQuickSearchResultsComponent } from 'product-quick-search-ui';

@Component({
  selector: 'ideal-product-quick-search-results-provider',
  standalone: true,
  imports: [ProductQuickSearchResultsComponent, RenderInAppQuickSearchResultsDirective],
  templateUrl: './product-quick-search-results-provider.component.html',
  styleUrl: './product-quick-search-results-provider.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductQuickSearchResultsProviderComponent {
  private readonly _searchService = inject(SearchService) as SearchService<ProductQuickSearchListItem[]>;
  protected readonly searchResults = this._searchService.searchResults;
  protected readonly searchQuery = this._searchService.searchQuery;
}
