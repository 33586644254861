<ideal-edit-item-toolbar [formGroup]="form">
  <ng-template #edit>
    <ideal-text-input formControlName="firstName" label="First Name" placeholder="Enter your First Name" />
  </ng-template>
</ideal-edit-item-toolbar>

<!-- <ideal-dialog [displayInline]="toggleInline" [(open)]="toggleOpen">
  <ng-template #content>
    <div>Content</div>
    <div>Content</div>
    <div>Content</div>
    <div>Content</div>
    <div>Content</div>
  </ng-template>
</ideal-dialog>
<button (click)="toggleOpen = !toggleOpen">{{toggleOpen ? 'Close' : 'Open'}}</button>
<button (click)="toggleInline = !toggleInline">{{toggleInline ? 'Popup Mode' : 'Inline Mode'}}</button>




 -->

<!-- <form #formElm [formGroup]="form" (ngSubmit)="onSubmit($event)" (reset)="onReset($event)">
  <ideal-label-frame label="Contact">
    <ideal-text-input formControlName="firstName" label="First Name" placeholder="Enter your First Name">
      @if (firstNameControl.hasError("x")) {
        <mat-error>No X!</mat-error>
      }
    </ideal-text-input>
    <ideal-text-input formControlName="lastName" label="Last Name" placeholder="Enter your Last Name"> </ideal-text-input>
    <ideal-number-input formControlName="age" label="Age" placeholder="Enter your Age"> </ideal-number-input>
    <ideal-password-input formControlName="password" label="Password" placeholder="Enter your Password" passwordVisibility="down">
    </ideal-password-input>
    <ideal-password-input formControlName="passwordConfirm" label="Confirm Password" placeholder="Confirm your Password">
    </ideal-password-input>
    <ideal-textarea-input formControlName="notes" label="Notes"></ideal-textarea-input>
    <ideal-form-button-group>
      <button mat-flat-button color="primary" type="submit">Submit</button>
      <button mat-button type="reset" align="end">Reset</button>
    </ideal-form-button-group>
  </ideal-label-frame>
</form>


<pre>
  form: {{ form.value | json }}
  someSignal: {{ someSignal() | json }}
</pre> -->
