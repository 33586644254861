import { ChangeDetectionStrategy, Component, computed, inject } from '@angular/core';
import { AppService, AppSideDrawerDirective, RenderInAppActionsMainDirective } from 'app-shell-ui';
import { CartMenuButtonComponent, CartMenuComponent } from 'cart-ui';

@Component({
  selector: 'ideal-import-cart-menu',
  standalone: true,
  imports: [AppSideDrawerDirective, CartMenuButtonComponent, CartMenuComponent, RenderInAppActionsMainDirective],
  templateUrl: './import-cart-menu.component.html',
  styleUrl: './import-cart-menu.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImportCartMenuComponent {
  private readonly _appService = inject(AppService);

  protected readonly isOpen = computed(() => this._appService.sideDrawers.openedDrawer() === 'cart');

  protected toggleCart() {
    this._appService.sideDrawers.toggle('cart');
  }
}
