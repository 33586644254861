import { Provider } from '@angular/core';
import { provideRoutes } from 'routing-util';
import { LegacyFeatureRoutesConfig } from './LegacyFeatureRoutesConfig';
import { defaultLegacyFeatureRoutesConfig } from './defaultLegacyFeatureRoutesConfig';

export function provideLegacyFeatureRoutes(config?: Partial<LegacyFeatureRoutesConfig>): Provider {
  const useConfig = { ...defaultLegacyFeatureRoutesConfig, ...config };
  return [
    provideRoutes([
      {
        path: 'products',
        loadChildren: () => import('feature-products').then((m) => m.productRoutes),
      },
      {
        path: 'promotions',
        loadChildren: () => import('feature-promotions').then((m) => m.promotionsRoutes),
      },
      {
        path: 'vendors',
        loadChildren: () => import('feature-shop').then((m) => m.shopRoutes),
      },
      {
        path: 'orders',
        loadChildren: () => import('feature-orders').then((m) => m.orderRoutes),
      },
      {
        path: 'checkout',
        loadChildren: () => import('feature-orders').then((m) => m.checkoutRoutes),
      },
      {
        path: 'quotes',
        loadChildren: () => import('feature-quotes').then((m) => m.quoteRoutes),
      },
      {
        path: 'cart',
        loadChildren: () => import('feature-cart').then((m) => m.cartRoutes),
      },
      {
        path: 'carts',
        loadChildren: () => import('feature-cart').then((m) => m.savedCartRoutes),
      },
      {
        path: 'part-lists',
        loadChildren: () => import('feature-cart').then((m) => m.savedListRoutes),
      },
      {
        path: useConfig.profilePath,
        loadChildren: () => import('feature-customer-profile').then((m) => m.routes),
      },
    ]),
  ];
}
